<template>
     <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        @close="cancleEvent('ruleForm')">
        <div class="content-box">
            <div class="menu-type">
                <span>菜单类型</span>
                <ul>
                    <li v-for="(item,index) in list" :key="index" :class="{'active' : activeIndex == index }" @click="handleTab(index)">
                        {{item.title}}
                    </li>
                </ul>
            </div>
            <div class="prev-menu">
                <span>上级菜单</span>
                <treeselect v-model="parentId"
                    :options="menuOptions"
                    :normalizer="normalizer"
                    :show-count="true"
                    placeholder="选择上级菜单"
                />
            </div>
        </div>
        <div>
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="图标" prop="icon" v-if="activeIndex == 0 || activeIndex == 2">
                        <el-popover
                            placement="bottom-start"
                            width="460"
                            v-model="popoverVisible">
                            <div class="icon-list">
                                <div v-for="(iconItem, inds) in iconList" :key="inds" @click="selectedIcon(iconItem)">
                                    <svg-icon :icon-class="iconItem" style="height: 30px;width: 16px;" />
                                    <span>{{ iconItem }}</span>
                                </div>
                            </div>
                            <el-input slot="reference" v-model="ruleForm.icon" placeholder="点击选择图标" readonly @click="openPopoverEvent">
                                <svg-icon
                                    v-if="ruleForm.icon"
                                    slot="prefix"
                                    :icon-class="ruleForm.icon"
                                    class="el-input__icon"
                                    style="height: 32px;width: 16px;"
                                />
                                <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                            </el-input>
                        </el-popover>
                    </el-form-item>
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入菜单名称"></el-input>
                    </el-form-item>
                    <el-form-item label="路由地址" prop="path" v-if="activeIndex == 0 || activeIndex == 2">
                        <el-input v-model="ruleForm.path" placeholder="请输入路由地址"></el-input>
                    </el-form-item>
                    <el-form-item label="权限标识" prop="permission" v-if="activeIndex == 1">
                        <el-input v-model="ruleForm.permission" placeholder="请输入权限标识"></el-input>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input-number v-model="ruleForm.sort" controls-position="right" :min="0"></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancleEvent('ruleForm')">取 消</el-button>
            <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import icons from './RequireIcons.js'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { menusAPI } from 'liankong-ui-api' 

export default {
    data(){
        return{
          dialogVisible: false,
          title: '', // 动态弹框title
          iconList: icons, // 图标
          activeIndex: 0, // 菜单类型
          popoverVisible: false, // popover框手动显示关闭
          list: [{title:'左菜单'},{title:'按钮'},{title:'顶菜单'}],
          parentId: undefined, // 上级菜单
          ruleForm: {
            icon: '',
            name: '',
            path: '',
            sort: '',
            permission: ''
          },
          rules: {
            icon: [
                { required: true, message: '图标不能为空', trigger: 'blur' },
            ],
            name: [
                { required: true, message: '菜单名称不能为空', trigger: 'blur' },
            ],
            path: [
                { required: true, message: '路由地址不能为空', trigger: 'blur' },
            ],
            sort: [
                { required: true, message: '菜单顺序不能为空', trigger: 'blur' },
            ],
            permission: [
                { required: true, message: '权限标识不能为空', trigger: 'blur' },
            ]
          },
          menuOptions:[], // 上级菜单数据
          editId: '', // 修改需要传的id
          newObj: {}
        }
    },
    components: {
        Treeselect
    },
    created(){ },
    methods:{
        init(isEdit, row){
            if (isEdit) { // 如果是修改
                this.activeIndex = row.type  // 菜单类型赋值
                this.editId = row.id

                let getMenuList = this.$parent.menuList
                if(row.parentId == '-1') {
                    this.parentId = '-1'; 
                } else {
                    let results = this.filterData(getMenuList,row)
                    this.parentId = results.id;      // 上级菜单赋值
                }
            } else {
                this.parentId = undefined;
                this.activeIndex = 0; 
                this.editId = ''
            }
            this.getTreeMenuList();
            this.$nextTick(() => {
                this.$refs['ruleForm'].resetFields();
                if (isEdit) {
                    this.ruleForm = {
                        icon: row.icon,
                        name: row.name,
                        path: row.path,
                        sort: row.order,
                        permission: row.permission
                    }
                } else {
                     this.ruleForm = {
                        icon: '',
                        name: '',
                        path: '',
                        sort: '',
                        permission: ''
                    }
                }
            })
        },
        openPopoverEvent(){
            this.popoverVisible = true;
        },
        handleTab(index) {
            this.activeIndex = index;
        },
         // 选择图标
        selectedIcon(name){
            this.ruleForm.icon = name;
            this.popoverVisible = false;
        },
        cancleEvent(formName){
            this.$refs[formName].resetFields();
            this.dialogVisible = false;
        },
        // 上级菜单 列表
        getTreeMenuList(){
            menusAPI.getTreeList(false).then(res=>{
                this.menuOptions = [];
                const menu = {id: -1, name: '根菜单', children: []};
                menu.children = res.data;
                this.menuOptions.push(menu);
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        /** 转换菜单数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.name,
                children: node.children
            };
        },
        filterData(getMenuList,row){
            getMenuList.filter(eveys=>{
                if(eveys.id == row.parentId){
                    this.newObj = eveys
                }
                if(eveys.children && eveys.children.length > 0) {
                    this.filterData(eveys.children, row)
                }
            })
            return this.newObj
        },
        // 确定提交表单
        dataFormSubmit(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if (this.parentId === undefined) {
                        this.parentId = -1
                    }
                    if(this.title == '新增') {
                        let params = {
                            "icon": this.ruleForm.icon, // 权限图标
                            "name": this.ruleForm.name, // 菜单名称
                            "order": this.ruleForm.sort, // 排序
                            "parentId": this.parentId, // 父级id
                            "path": this.ruleForm.path, // 前端路由标识
                            "permission": this.ruleForm.permission, // 菜单权限标识
                            "state": "0", // 1:已删除,0:正常
                            "type": this.activeIndex // 菜单类型,0:菜单 1:按钮 2 顶菜单
                        }
                        menusAPI.addData(params).then(res=>{
                            if(res.code == 0) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                });
                                this.dialogVisible = false;
                                this.$parent.getList()
                            } else {
                                this.$message.error(res.message);
                                this.dialogVisible = false;
                            }
                        }).catch(error=>{
                            this.$message.error(error.message);
                        })
                    } else {
                        let editParams = {
                            "id": this.editId,
                            "icon" : this.ruleForm.icon,
                            "name": this.ruleForm.name,
                            "order": this.ruleForm.sort,
                            "parentId": this.parentId,
                            "path": this.ruleForm.path,
                            "permission": this.ruleForm.permission,
                            "state": "0",
                            "type": this.activeIndex
                        }
                        menusAPI.editTreeMenu(editParams).then(datas=>{
                            if(datas.code == '0') {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                });
                                this.dialogVisible = false;
                                this.$parent.getList()
                            } else {
                                this.$message.error(datas.message);
                                this.dialogVisible = false;
                            }
                        }).catch(error=>{
                            this.$message.error(error.message);
                        })
                    }
                }
            })

        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding: 15px 20px;
}
.content-box {
   display: flex;
   justify-content: space-between;
}
.menu-type {
    display: flex;
    align-items: center;
    ul {
        padding: 0;
        list-style: none;
        display: flex;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        margin-left: 10px !important;
        li {
            padding: 8px 17px;
            cursor: pointer;
        }
        li:nth-child(1){
            border-right: 1px solid #e2e2e2;
        }
        li:nth-child(2){
            border-right: 1px solid #e2e2e2;
        }
        .active {
            background: #409EFF;
            color: #fff;
            // border-radius: 5px 0 0 5px;
        }
    }
}
.prev-menu {
    display: flex;
    align-items: center;
    span{
        margin-right: 10px;
    }
}
.vue-treeselect {
    width: 200px;
}
.icon-list {
    height: 200px;
    overflow-y: scroll;
    div {
        height: 40px;
        line-height: 40px;
        margin-bottom: -5px;
        cursor: pointer;
        width: 33%;
        float: left;
    }
    span {
        display: inline-block;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
}
</style>