<template>
    <div class="menu-wrap">
        <el-button type="primary" @click="addBtnDialog('新增',false)" v-if="newObj.sys_menu_add">
            <i class="el-icon-plus"></i>
            添加
        </el-button>

        <el-table
            style="margin-top: 25px;"
            border
            v-loading="loading"
            :data="menuList"
            row-key="id"
            :tree-props="{children: 'children', hasChildren: 'hasChildrens'}">
            <el-table-column prop="name" label="菜单名称" :show-overflow-tooltip="true" width="180"></el-table-column>
            <el-table-column prop="icon" label="图标" align="center" width="100">
            <template slot-scope="scope">
                <svg-icon :icon-class="scope.row.icon" />
            </template>
            </el-table-column>
            <el-table-column prop="order" label="排序" width="60"></el-table-column>
            <el-table-column prop="path" label="组件路径" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" label="类型" width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.type === '0'">左菜单</el-tag>
                    <el-tag type="success" v-if="scope.row.type === '2'">顶菜单</el-tag>
                    <el-tag type="info" v-if="scope.row.type === '1'">按钮</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="permission" label="权限标识" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
                <el-button
                size="small"
                type="text"
                icon="el-icon-plus"
                v-if="newObj.sys_menu_add"
                @click="addBtnDialog('新增',false,scope.row)">新增
                </el-button>
                <el-button size="small"
                        type="text"
                        icon="el-icon-edit"
                        v-if="newObj.sys_menu_edit"
                        @click="addBtnDialog('修改',true,scope.row)">修改
                </el-button>
                <el-button
                size="small"
                type="text"
                icon="el-icon-delete"
                v-if="newObj.sys_menu_del"
                @click="handleDelete(scope.row)">删除
                </el-button>
            </template>
            </el-table-column>
        </el-table>
        <menu-item ref="addOrUpdate"></menu-item>
    </div>
</template>
<script>
import MenuItem from './Menu-item.vue'
import { menusAPI } from 'liankong-ui-api' 

export default {
    data(){
        return{
          // 遮罩层
          loading: false,
          menuList: [], // 树形表格数据
          newObj: {}
        }
    },
    components: {
        MenuItem
    },
    computed:{
        roles(){
            return this.$store.state.login.roles || []
        }
    },
    created(){

        this.getList()

        this.roles.forEach(roItem=>{
            this.newObj[roItem.authority] = roItem.authority
        })
    },
    methods:{
        getList(){
            menusAPI.getTreeList(false).then(res=>{
                if(res.code == '0') {
                    this.menuList = res.data
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 新增、编辑
        addBtnDialog(tips,isEdit,row){
            this.$refs.addOrUpdate.title = tips;
            this.$refs.addOrUpdate.dialogVisible = true;
            this.$nextTick(() => {
              this.$refs.addOrUpdate.init(isEdit, row)
            })
        },
        // 删除
        handleDelete(row){
            this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                menusAPI.deleteMenu(row.id).then(res=>{
                    if(res.code == '0'){
                        this.getList();
                        this.$message.success('删除成功')
                    } else if(res.code == '1'){
                        this.$message.error(res.message)
                    }
                }).catch(error=>{
                    this.$message.error(error.message);
                })
                
            })
        },
       
    }
}
</script>

<style lang="less" scoped>
.menu-wrap {
    padding: 20px;
}
</style>